<script lang="ts" setup>
</script>
<template>
  <div class="h-dvh max-h-dvh overflow-hidden">
    <div class="border-b p-3 text-center">Navbar</div>
    <div class="flex h-full overflow-hidden">

      <!-- sidebar -->
      <div class="border-r lg:min-w-72 text-center flex flex-col items-stretch bg-green-100 p-6">
        <Button class="w-full" variant="ghost">
          <NuxtLink to="/app">Restaurant menu</NuxtLink>
        </Button>
        <hr />
        <Button class="w-full" variant="ghost">
          <NuxtLink to="/admin/orders">Orders</NuxtLink>
        </Button>
        <Button class="w-full" variant="ghost">
          <NuxtLink to="/admin/customers">Customers</NuxtLink>
        </Button>
        <hr />
        <hr />
        <Button class="w-full" variant="ghost">
          <NuxtLink to="/admin/menu">My menu</NuxtLink>
        </Button>
        <Button class="w-full" variant="ghost">
          <NuxtLink to="/admin/items">Products</NuxtLink>
        </Button>
        <Button class="w-full" variant="ghost">
          <NuxtLink to="/admin/categories">Categories</NuxtLink>
        </Button>
        <hr />
        <Button class="w-full" variant="ghost">
          <NuxtLink to="/admin/codes">QR Code</NuxtLink>
        </Button>
        <hr />
        <Button class="w-full" variant="ghost">
          <NuxtLink to="/">Exit</NuxtLink>
        </Button>
      </div>

      <!-- content -->
      <div class="flex bg-purple-100 flex-1 p-8">
        <NuxtPage class="overflow-y-scroll bg-orange-100 w-full rounded" />
      </div>
    </div>
  </div>
</template>